var render = function () {
  var _vm$news$, _vm$news$2, _vm$news$3, _vm$news$4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Overview",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', [_c('h1', [_vm._v("Willkommen auf Ihrem Dashboard")]), _c('p', {
    staticStyle: {
      "font-size": "1.5rem"
    }
  }, [_vm._v(_vm._s(_vm.contactPerson))])]), _c('div', {
    staticStyle: {
      "font-size": "1rem",
      "text-align": "center",
      "margin-bottom": "10px",
      "margin-top": "-40px"
    }
  }, [_c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.startDate
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  })], 1), _vm.data.length ? _c('ChartV2', {
    attrs: {
      "halfHeight": "",
      "color": "green",
      "type": "bar",
      "values": _vm.data,
      "title": _vm.priceToEuroString(_vm.monthlyIncome)
    },
    on: {
      "onLeave": function onLeave($event) {
        _vm.activeRow = null;
      }
    }
  }) : _vm._e(), !_vm.hasNoData ? _c('div', {
    staticClass: "Wrap"
  }, [_c('Card', {
    staticClass: "col"
  }, [_vm.sortedDrivers.length > 0 ? _c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "variant": "minimal",
      "parentHover": false,
      "items": {
        row: _vm.driverHeader
      },
      "index": -1
    },
    on: {
      "onClickColumn": _vm.handleDriverHeaderClick
    }
  }) : _vm._e(), _vm._l(_vm.sortedDrivers, function (driver, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": driver,
        "hasBadge": driver.isActive,
        "index": i,
        "isActive": driver.isActive
      },
      on: {
        "onClick": _vm.handleDriverClick,
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.sortedDrivers.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _vm.hasReminderFeature ? _c('Card', {
    staticClass: "col"
  }, [_vm._l(_vm.groupedReminders, function (reminders, i) {
    return _c('div', {
      key: i,
      staticStyle: {
        "margin-bottom": "20px"
      }
    }, [reminders.entries.length > 0 ? _c('Row', {
      key: -1,
      staticClass: "Row-Header",
      attrs: {
        "variant": "minimal",
        "parentHover": false,
        "items": {
          row: _vm.reminderHeader.map(function (h, i) {
            return Object.assign({}, h, {
              name: i === 0 ? h.name + ' ' + reminders.entity : h.name
            });
          })
        },
        "index": -1
      },
      on: {
        "onClickColumn": function onClickColumn() {}
      }
    }) : _vm._e(), _vm._l(reminders.entries, function (rowItem, j) {
      return _c('Row', {
        key: j,
        attrs: {
          "variant": 'minimal',
          "hasBadge": rowItem.hasBadge,
          "items": rowItem,
          "index": j
        },
        on: {
          "onClick": function onClick($event) {
            return _vm.handleReminderClick(rowItem);
          }
        }
      });
    })], 2);
  }), !_vm.groupedReminders.length ? _c('p', [_vm._v("Noch keine Erinnerungen vorhanden.")]) : _vm._e()], 2) : _vm._e(), _c('Card', {
    staticClass: "col"
  }, [_vm.sortedCars.length > 0 ? _c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "variant": "minimal",
      "parentHover": false,
      "items": {
        row: _vm.carHeader
      },
      "index": -1
    },
    on: {
      "onClickColumn": _vm.handleCarHeaderClick
    }
  }) : _vm._e(), _vm._l(_vm.sortedCars, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": car,
        "index": i,
        "hasBadge": car.isActive,
        "isActive": car.isActive
      },
      on: {
        "onClick": _vm.handleCarClick,
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.sortedCars.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col col--individual-performance"
  }, [_c('h3', [_vm._v("Km-Schnitt: " + _vm._s(_vm.selectedDriver.name || _vm.selectedCar.licenseNumber))]), _c('div', {
    staticClass: "performance-wrap"
  }, [_c('Progress', {
    attrs: {
      "percent": _vm.selectedItemPerformance.percentage
    }
  }), _c('div', {
    staticClass: "performance-values"
  }, [_c('div', [_c('span', [_vm._v(" Gesamt-Km: ")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.distanceToString(_vm.selectedItem.totalDistance) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Besetzt-Km: ")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.distanceToString(_vm.selectedItem.hiredDistance) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Besetzt-%:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.percentageToString(_vm.selectedItem.hiredDistance / (_vm.selectedItem.totalDistance || 1)) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Schichtzeit:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.timeToString(_vm.selectedItem.shiftTime) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Einnahmen:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? _vm.priceToEuroString(_vm.selectedItem.income) : 0) + " ")]), _c('div', [_c('span', [_vm._v(" Euro/Km:")]), _vm._v(" " + _vm._s(_vm.selectedItem ? '≈' + (_vm.selectedItem.income * 10 / (_vm.selectedItem.totalDistance || 1)).toFixed(2) + ' €' : 0) + " ")])])], 1)]), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Höchster Umsatz")]), _vm._l(_vm.highestIncomeCarAndDriver, function (highestIncome, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": highestIncome,
        "index": -1
      },
      on: {
        "onClick": function onClick($event) {
          highestIncome.licenseNumber ? _vm.handleCarClick({
            item: highestIncome
          }) : _vm.handleDriverClick({
            item: highestIncome
          });
        },
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.highestIncomeCarAndDriver.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Durchschnittlicher Umsatz")]), _vm._l(_vm.averageIncomeCarsAndDrivers, function (averageIncome, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": averageIncome,
        "index": -1
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.averageIncomeCarsAndDrivers.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _vm.widgets.find(function (widget) {
    return widget.type === 'targetIncome';
  }) ? _c('Card', {
    staticClass: "col"
  }, [_c('div', [_c('h3', [_vm._v("Erreichter Umsatz")])]), _c('IconCog', {
    staticStyle: {
      "position": "absolute",
      "top": "10px",
      "right": "10px",
      "z-index": "1",
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleOnSettingsClick.apply(null, arguments);
      }
    }
  }), _c('p', [_vm._v("Erreicht: " + _vm._s(_vm.priceToEuroString(_vm.monthlyIncome)))]), _c('p', [_vm._v(" Ziel: " + _vm._s(_vm.priceToEuroString(_vm.targetIncome * 100)) + " ")]), _c('p', [_vm._v(" Ausstehen: " + _vm._s(_vm.priceToEuroString(_vm.remainingIncome)) + " ")]), _c('br'), _c('Progress', {
    attrs: {
      "variant": "vertical",
      "percent": Math.min(_vm.monthlyIncome / (_vm.targetIncome * 100) * 100, 100)
    }
  })], 1) : _vm._e(), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Kein Signal über 2+ Tage")]), _vm._l(_vm.carsWithNoPingInLast3Days, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": car,
        "index": i
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.carsWithNoPingInLast3Days.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Keine Daten über 2+ Tage")]), _vm._l(_vm.carsWithNoDataInLast3Days, function (car, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": car,
        "index": i
      },
      on: {
        "onHover": function onHover(args) {
          return _vm.$emit('onRowHover', args);
        },
        "onLeave": function onLeave(args) {
          return _vm.$emit('onRowLeave', args);
        }
      }
    });
  }), !_vm.carsWithNoDataInLast3Days.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('div', [_c('h3', [_vm._v("Fehlerhafte Daten")])]), _vm.incorrectLogin.length > 0 ? _c('Button', {
    staticStyle: {
      "position": "absolute",
      "top": "15px",
      "right": "15px",
      "z-index": "1",
      "cursor": "pointer"
    },
    attrs: {
      "size": "small",
      "variant": "minimal"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleIncorrectEditClick.apply(null, arguments);
      }
    }
  }, [_vm._v("Bearbeiten")]) : _vm._e(), _vm._l(_vm.incorrectLogin, function (driver, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "variant": "minimal",
        "items": driver
      }
    });
  }), !_vm.incorrectLogin.length ? _c('p', [_vm._v("Noch keine Daten vorhanden.")]) : _vm._e()], 2), _c('Card', {
    staticClass: "col"
  }, [_c('h3', [_vm._v("Technischer Support")]), _c('p', [_c('b', [_vm._v("Telefon")]), _vm._v(":"), _c('a', {
    attrs: {
      "href": "tel:+4917672905450"
    }
  }, [_vm._v(" 0176 7290 5450 "), _c('br')]), _c('b', [_vm._v("Dienstag")]), _vm._v(": 14-16 Uhr "), _c('br'), _c('b', [_vm._v("Donnerstag")]), _vm._v(": 16-19 Uhr "), _c('br')]), _c('br'), _c('hr'), _c('br'), _c('p', [_c('b', [_vm._v("Email")]), _vm._v(": "), _c('a', {
    attrs: {
      "href": "mailto:info@taxifusion.de"
    }
  }, [_vm._v("info@taxifusion.de")]), _c('br'), _vm._v(" Antwort innerhalb 2 Werktagen ")])])], 1) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": "Umsatzziel ändern",
      "show": _vm.revenueGoalModal,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSaveRevenueGoal
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isSavingRevenueGoal
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('p', [_vm._v(" Bitte geben Sie den neuen Umsatz ein, den Sie erreichen möchten (in Euro) pro Monat. ")]), _c('br'), _c('br'), _c('label', [_vm._v("Umsatzziel (in Euro)")]), _c('Input', {
    attrs: {
      "type": "number",
      "keyName": "targetIncome",
      "value": _vm.targetIncome
    },
    on: {
      "onKeyPress": _vm.handleTargetIncomeChange
    }
  }), _c('br')], 1), _c('NewsModal', {
    attrs: {
      "show": _vm.news.length > 0,
      "title": (_vm$news$ = _vm.news[0]) === null || _vm$news$ === void 0 ? void 0 : _vm$news$.title,
      "uuid": (_vm$news$2 = _vm.news[0]) === null || _vm$news$2 === void 0 ? void 0 : _vm$news$2.uuid,
      "needsConfirmation": !!((_vm$news$3 = _vm.news[0]) !== null && _vm$news$3 !== void 0 && _vm$news$3.needsConfirmation),
      "message": (_vm$news$4 = _vm.news[0]) === null || _vm$news$4 === void 0 ? void 0 : _vm$news$4.text,
      "onHasConfirmed": _vm.handleNewsModelConfirm,
      "isLoading": _vm.isNewsModalLoading
    },
    on: {
      "onClose": _vm.handleNewsModalClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }