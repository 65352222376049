var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "NewsModal",
    attrs: {
      "show": _vm.show && !_vm.hasSeen,
      "title": _vm.title,
      "isLoading": _vm.isLoading,
      "action": {
        text: 'OK',
        color: 'green',
        callback: _vm.handleOkClick
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleClose
    }
  }, [_c('vue-markdown', {
    attrs: {
      "source": _vm.message
    }
  }), _vm.needsConfirmation ? _c('div', {
    staticClass: "NewsModal-Confirmation"
  }, [_c('Checkbox', {
    attrs: {
      "hasError": !_vm.hasConfirmed,
      "name": "hasConfirmed"
    },
    on: {
      "onCheck": _vm.onCheckboxChange
    }
  }, [_vm._v(" Hiermit bestätige ich, dass ich die Nachricht gelesen habe. ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }