var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-wrap",
    class: "is-".concat(_vm.variant)
  }, [_c('div', {
    staticClass: "progress-full"
  }), _c('div', {
    staticClass: "progress",
    class: _vm.percent < 25 ? 'is-low' : _vm.percent < 50 ? 'is-medium' : _vm.percent < 70 ? 'is-high' : 'is-full',
    style: _vm.variant === 'vertical' ? {
      width: "".concat(_vm.percent, "%")
    } : {
      height: "".concat(_vm.percent, "%")
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }