<template>
    <div class="progress-wrap" :class="`is-${variant}`">
        <div class="progress-full" />
        <div
            class="progress"
            :class="
                percent < 25
                    ? 'is-low'
                    : percent < 50
                    ? 'is-medium'
                    : percent < 70
                    ? 'is-high'
                    : 'is-full'
            "
            :style="variant === 'vertical' ? { width: `${percent}%` } : { height: `${percent}%` }"
        />
    </div>
</template>
<script>
export default {
    props: {
        variant: String,
        percent: Number,
    },
};
</script>
<style lang="scss" scoped>
.progress-wrap {
    position: relative;
    width: 50px;
    height: 100%;

    .progress,
    .progress-full {
        background-color: color(bg);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 100%;
        border-radius: 5px;
    }

    .progress {
        width: 50px;
        transition: all 0.3s ease;

        &.is-low {
            background-color: color(red);
        }
        &.is-medium {
            background-color: color(yellow-dark);
        }
        &.is-high {
            background-color: color(green);
        }
        &.is-full {
            background-color: #49ee7e;
        }
    }

    &.is-vertical {
        height: 50px;
        width: 100%;

        .progress,
        .progress-full {
            height: 50px;
            width: 100%;
        }
    }
}
</style>
