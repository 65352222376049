<template>
    <Modal
        class="NewsModal"
        :show="show && !hasSeen"
        :title="title"
        :isLoading="isLoading"
        :action="{ text: 'OK', color: 'green', callback: handleOkClick }"
        :cancel="{ text: 'Abbrechen' }"
        @onModalClose="handleClose"
    >
        <vue-markdown :source="message"></vue-markdown>
        <div v-if="needsConfirmation" class="NewsModal-Confirmation">
            <Checkbox @onCheck="onCheckboxChange" :hasError="!hasConfirmed" name="hasConfirmed">
                Hiermit bestätige ich, dass ich die Nachricht gelesen habe.
            </Checkbox>
        </div>
    </Modal>
</template>

<script>
import Checkbox from '@/components/widgets/Checkbox';

import VueMarkdown from 'vue-markdown';
import Modal from '@/components/widgets/Modal';

export default {
    name: 'NewsModal',
    components: {
        VueMarkdown,
        Modal,
        Checkbox,
    },
    props: {
        uuid: {
            type: String,
            default: '',
        },
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        onHasConfirmed: {
            type: Function,
            default: () => {},
        },
        needsConfirmation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasConfirmed: false,
        };
    },
    computed: {
        hasSeen() {
            return false;
        },
    },
    methods: {
        async handleOkClick() {
            if (this.needsConfirmation && !this.hasConfirmed) {
                this.$toasted.error('Bitte bestätigen Sie, dass Sie die Nachricht gelesen haben.');
                return;
            }
            await this.onHasConfirmed();
            this.handleClose();
        },
        onCheckboxChange(checked) {
            this.hasConfirmed = checked;
        },
        handleClose() {
            this.$emit('onClose');
            // use local storage to store the seen news
            window.localStorage.setItem(`news-${this.uuid}`, 'true');
        },
    },
};
</script>

<style lang="scss">
.NewsModal {
    .Modal-Children {
        padding: 15px 15px 30px !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Arial', sans-serif;
        margin-bottom: 0.75em;
        margin-top: 0;
        font-weight: bold;
    }

    h1 {
        font-size: 1.5rem;
        color: #373737;
    }

    h2 {
        font-size: 1.25rem;
        color: #444;
    }

    h3 {
        font-size: 1.15rem;
        color: #555;
    }

    h4 {
        font-size: 1rem;
        color: #666;
    }

    h5 {
        font-size: 1rem;
        color: #777;
    }

    h6 {
        font-size: 0.875rem;
        color: #888;
    }

    /* Styles for paragraphs */
    p {
        font-size: 1rem;
        line-height: 1.5;
        color: #333;
        margin: 0;
    }

    /* Styles for images */
    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 1em 0;
    }

    /* Styles for lists */
    ul,
    ol {
        margin: 1em 0;
        padding-left: 1.5em;
    }

    li {
        margin: 0.5em 0;
    }

    /* Styles for links */
    a {
        color: #007bff;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    /* Styles for blockquotes */
    blockquote {
        margin: 1em 0;
        padding: 0.5em 1em;
        border-left: 5px solid #ccc;
        background-color: #f9f9f9;
    }

    /* Styles for code blocks */
    pre {
        background: #f5f5f5;
        padding: 1em;
        overflow-x: auto;
    }

    code {
        font-family: 'Courier New', Courier, monospace;
        background: #f5f5f5;
        padding: 0.2em 0.4em;
        border-radius: 3px;
    }
}
</style>
